/* eslint-env node */
import React from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { THEME_CONFIG } from './configs/AppConfig';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
// import mixpanel from 'mixpanel-browser';
// import FlagProvider from '@unleash/proxy-client-react';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};
// const config = {
//   url: 'https://round-signal-betaflag-proxy.herokuapp.com/proxy',
//   clientKey: 'some-secret',
//   refreshInterval: 15,
//   appName: 'round-signal-web',
//   environment: process.env.NODE_ENV,
// };

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <FlagProvider config={config}> */}
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point">
            <Router>
              <Switch>
                <Route path="/" component={Views} />
              </Switch>
            </Router>
          </ThemeSwitcherProvider>
          {/* </FlagProvider> */}
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
