import fetch from 'auth/FetchInterceptor';

const LibraryService = {};

LibraryService.getVehicles = function (params) {
  return fetch({
    url: `/library/vehicle/make?withModels=true`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getCargoCategories = function (params) {
  return fetch({
    url: `/library/cargo/category?withItems=true`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getCargoTypes = function (params) {
  return fetch({
    url: `/library/cargo/type?withSizes=true`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getCargoItems = function (params) {
  return fetch({
    url: `/library/cargo/item?withCategory=true&&withDutygroup=true`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getCities = function (params) {
  return fetch({
    url: `/library/locations/cities`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getSeaPorts = function (params) {
  return fetch({
    url: `/library/locations/seaports`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getIncountryRoutes = function (params) {
  return fetch({
    url: `/routes/incountry`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.createBulkRatesIncountry = function (type, data) {
  return fetch({
    url: `/rates/incountry/${type}/bulk`,
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.getCrossBorderRoutes = function (params) {
  return fetch({
    url: `/routes/crossborder`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getSharedAddresses = function (params) {
  return fetch({
    url: `/library/locations/addresses`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

// CRUD for Rates Crossborder

LibraryService.getRatesCrossborder = function (type, params) {
  return fetch({
    url: `/rates/crossborder/${type}`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.createRatesCrossborder = function (type, data) {
  return fetch({
    url: `/rates/crossborder/${type}`,
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.createBulkRatesCrossborder = function (type, data) {
  return fetch({
    url: `/rates/crossborder/${type}/bulk`,
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.updateRatesCrossborder = function (type, id, data) {
  return fetch({
    url: `/rates/crossborder/${type}/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.deleteRatesCrossborder = function (type, id) {
  return fetch({
    url: `/rates/crossborder/${type}/${id}`,
    method: 'delete',
    headers: {
      'public-request': 'false',
    },
  });
};

LibraryService.updateDisableRatesCrossborder = function (type, id, action) {
  return fetch({
    url: `/rates/crossborder/${type}/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data: { is_disable: action },
  });
};

// CRUD for Rates Loading

LibraryService.getRatesLoading = function (type, params) {
  return fetch({
    url: `/rates/loading/${type}`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.createRatesLoading = function (type, data) {
  return fetch({
    url: `/rates/loading/${type}`,
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.createBulkRatesLoading = function (type, data) {
  return fetch({
    url: `/rates/loading/${type}/bulk`,
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.updateRatesLoading = function (type, id, data) {
  return fetch({
    url: `/rates/loading/${type}/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.deleteRatesLoading = function (type, id) {
  return fetch({
    url: `/rates/loading/${type}/${id}`,
    method: 'delete',
    headers: {
      'public-request': 'false',
    },
  });
};

// CRUD for Rates Offloading

LibraryService.getRatesOffloading = function (type, params) {
  return fetch({
    url: `/rates/offloading/${type}`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.createRatesOffloading = function (type, data) {
  return fetch({
    url: `/rates/offloading/${type}`,
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.createBulkRatesOffloading = function (type, data) {
  return fetch({
    url: `/rates/offloading/${type}/bulk`,
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.updateRatesOffloading = function (type, id, data) {
  return fetch({
    url: `/rates/offloading/${type}/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.deleteRatesOffloading = function (type, id) {
  return fetch({
    url: `/rates/offloading/${type}/${id}`,
    method: 'delete',
    headers: {
      'public-request': 'false',
    },
  });
};

LibraryService.updateDisableRatesLoading = function (type, id, action) {
  return fetch({
    url: `/rates/loading/${type}/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data: { is_disable: action },
  });
};

LibraryService.updateDisableRatesOffloading = function (type, id, action) {
  return fetch({
    url: `/rates/offloading/${type}/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data: { is_disable: action },
  });
};

LibraryService.getCurrency = function (params) {
  return fetch({
    url: `/library/currency`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.convertCurrency = function (params) {
  return fetch({
    url: `/library/currency/convert`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getLocationType = function (params) {
  return fetch({
    url: `/library/locationTypes`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getShipmentTypeSize = function (params) {
  return fetch({
    url: `/library/shipment`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getVehicleClearingNGPrices = function (params) {
  return fetch({
    url: `/library/clearing/ng/customs/vehicle/`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.createVehicleClearingNGPrices = function (data) {
  return fetch({
    url: `/library/clearing/ng/customs/vehicle/`,
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.createBulkVehicleClearingNGPrices = function (data) {
  return fetch({
    url: `/library/clearing/ng/customs/vehicle/bulk`,
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.updateVehicleClearingNGPrices = function (id, data) {
  return fetch({
    url: `/library/clearing/ng/customs/vehicle/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.updateDisableVehicleClearingNGPrices = function (id, action) {
  return fetch({
    url: `/library/clearing/ng/customs/vehicle/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data: { is_disable: action },
  });
};

LibraryService.deleteVehicleClearingNGPrices = function (id) {
  return fetch({
    url: `/library/clearing/ng/customs/vehicle/${id}`,
    method: 'delete',
    headers: {
      'public-request': 'false',
    },
  });
};

LibraryService.getCustomBrokersByConsolidator = function (consolidatorId) {
  return fetch({
    url: `/custom_brokers/consolidator/map/${consolidatorId}`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
  });
};

LibraryService.getCustomBrokers = function (params) {
  return fetch({
    url: `/custom_brokers/`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getCIFRates = function (params) {
  return fetch({
    url: '/library/clearing/ng/cif',
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getYards = function (params) {
  return fetch({
    url: '/library/yards',
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getClearingDurations = function (params) {
  return fetch({
    url: '/library/clearing/custom_broker_terminal_duration/',
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.createClearingDurations = function (data) {
  return fetch({
    url: '/library/clearing/custom_broker_terminal_duration/',
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.updateClearingDurations = function (id, data) {
  return fetch({
    url: `/library/clearing/custom_broker_terminal_duration/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.updateDisableClearingDurations = function (id, action) {
  return fetch({
    url: `/library/clearing/custom_broker_terminal_duration/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data: { is_disable: action },
  });
};

LibraryService.deleteClearingDurations = function (id) {
  return fetch({
    url: `/library/clearing/custom_broker_terminal_duration/${id}`,
    method: 'delete',
    headers: {
      'public-request': 'false',
    },
  });
};

LibraryService.getTruckingClearingRates = function (params) {
  return fetch({
    url: '/library/clearing/trucking_clearing_rate/',
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.createTruckingClearingRates = function (data) {
  return fetch({
    url: '/library/clearing/trucking_clearing_rate/',
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.updateTruckingClearingRates = function (id, data) {
  return fetch({
    url: `/library/clearing/trucking_clearing_rate/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.updateDisableTruckingClearingRates = function (id, action) {
  return fetch({
    url: `/library/clearing/trucking_clearing_rate/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data: { is_disable: action },
  });
};

LibraryService.deleteTruckingClearingRates = function (id) {
  return fetch({
    url: `/library/clearing/trucking_clearing_rate/${id}`,
    method: 'delete',
    headers: {
      'public-request': 'false',
    },
  });
};

LibraryService.getTerminals = function (params) {
  return fetch({
    url: '/library/terminals/',
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getConsolidatorVehicleTypeSplitIndices = function (params) {
  return fetch({
    url: '/library/splitIndices/',
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.createConsolidatorVehicleTypeSplitIndices = function (data) {
  return fetch({
    url: '/library/splitIndices/',
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.updateConsolidatorVehicleTypeSplitIndices = function (id, data) {
  return fetch({
    url: `/library/splitIndices/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.updateDisableConsolidatorVehicleTypeSplitIndices = function (id, action) {
  return fetch({
    url: `/library/splitIndices/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data: { is_disable: action },
  });
};

LibraryService.deleteConsolidatorVehicleTypeSplitIndices = function (id) {
  return fetch({
    url: `/library/splitIndices/${id}`,
    method: 'delete',
    headers: {
      'public-request': 'false',
    },
  });
};

// country library

LibraryService.getCountries = function (params) {
  return fetch({
    url: '/library/locations/countries/',
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getImportCountries = function (params) {
  return fetch({
    url: '/library/locations/countries/',
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

// Clearing Overhead NG
LibraryService.getClearingOverheadNG = function (params) {
  return fetch({
    url: '/library/clearing/clearing_overhead_ngs/',
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.createClearingOverheadNG = function (data) {
  return fetch({
    url: '/library/clearing/clearing_overhead_ngs/',
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.updateClearingOverheadNG = function (id, data) {
  return fetch({
    url: `/library/clearing/clearing_overhead_ngs/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.deleteClearingOverheadNG = function (id) {
  return fetch({
    url: `/library/clearing/clearing_overhead_ngs/${id}`,
    method: 'delete',
    headers: {
      'public-request': 'false',
    },
  });
};

LibraryService.syncClearingOverheadNG = function (data) {
  return fetch({
    url: '/library/clearing/clearing_overhead_ngs/sync/',
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.getCargoPricingStrategy = function (params) {
  return fetch({
    url: '/library/cargo/pricing_strategy/',
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.createCargoPricingStrategy = function (data) {
  return fetch({
    url: '/library/cargo/pricing_strategy/',
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.updateCargoPricingStrategy = function (id, data) {
  return fetch({
    url: `/library/cargo/pricing_strategy/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.deleteCargoPricingStrategy = function (id) {
  return fetch({
    url: `/library/cargo/pricing_strategy/${id}`,
    method: 'delete',
    headers: {
      'public-request': 'false',
    },
  });
};

LibraryService.getRatesClearingNGCargos = function (params) {
  return fetch({
    url: '/library/rates/clearing/cargo/',
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.createRatesClearingNGCargos = function (data) {
  const mData = { ...data, duty_group: 'red' };
  return fetch({
    url: '/library/rates/clearing/cargo/',
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data: mData,
  });
};

LibraryService.updateRatesClearingNGCargos = function (id, data) {
  return fetch({
    url: `/library/rates/clearing/cargo/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data,
  });
};

LibraryService.deleteRatesClearingNGCargos = function (id) {
  return fetch({
    url: `/library/rates/clearing/cargo/${id}`,
    method: 'delete',
    headers: {
      'public-request': 'false',
    },
  });
};

LibraryService.updateDisableRatesClearingNGCargos = function (id, action) {
  return fetch({
    url: `/library/rates/clearing/cargo/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data: { is_disable: action },
  });
};

// Shipment Sizes
LibraryService.getShipmentSizes = function (params) {
  return fetch({
    url: '/library/shipment/sizes/',
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getVehicleCategories = function (params) {
  return fetch({
    url: '/library/vehicle/category/',
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

LibraryService.getCustomsBrokerTerminalYard = function () {
  return fetch({
    url: '/library/clearing/custom_broker_terminal_yard/',
    method: 'get',
    headers: {
      'public-request': 'false',
    },
  });
};

LibraryService.getConstantsLength = function (params) {
  return fetch({
    url: '/library/services-prices/',
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params,
  });
};

export default LibraryService;
