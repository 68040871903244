import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthLayout from 'layouts/auth-layout';
import BookingLayout from 'layouts/booking-layout';
import AppLocale from 'lang';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, BOOKING_PREFIX_PATH, REVIEW_PREFIX_PATH } from 'configs/AppConfig';
import useBodyClass from 'hooks/useBodyClass';
import AppLayout from 'layouts/app-layout';
import DevTooling from 'components/util-components/DevTooling';
import QuotePageSharer from 'components/util-components/QuotePageSharer';
import ReviewByShipper from './review-views/review-by-shipper';
import ReviewByProvider from './review-views/review-by-provider';
import LangdingPageRoutePicker from './booking-flow/book-shipment/landing_page_route_picker';

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { locale, token, location, direction } = props;

  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <Route path={BOOKING_PREFIX_PATH}>
            <BookingLayout direction={direction} location={location} />
          </Route>
          <Route path={REVIEW_PREFIX_PATH}>
            <ReviewByShipper direction={direction} location={location} />
          </Route>
          <Route path={'/co/review'}>
            <ReviewByProvider direction={direction} location={location} />
          </Route>
          <Route path={'/landing_page_booking'}>
            <LangdingPageRoutePicker direction={direction} location={location} />
          </Route>
          {/* <RouteInterceptor path={BOOKING_PREFIX_PATH} isAuthenticated={!token}>
            <BookingLayout direction={direction} location={location}/>
          </RouteInterceptor> */}
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
        </Switch>
        {/* <DevTooling /> */}
        <QuotePageSharer />
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, direction, token };
};

export default withRouter(connect(mapStateToProps)(Views));
