/* eslint-env node */
const dev = {
  API_ENDPOINT_URL: `${process.env.REACT_APP_API_ENDPOINT_URL}/v1`,
  ROSA_API_ENDPOINT_URL: `${process.env.REACT_APP_ROSA_API_ENDPOINT_URL}`,
  ROSA_API_BEARER_TOKEN: `${process.env.REACT_APP_ROSA_API_BEARER_TOKEN}`,
  AMPLITUDE_API_KEY: 'ffb2784c0f24409961235025847611f4',
};

const prod = {
  API_ENDPOINT_URL: `${process.env.REACT_APP_API_ENDPOINT_URL}/v1`,
  ROSA_API_ENDPOINT_URL: `${process.env.REACT_APP_ROSA_API_ENDPOINT_URL}`,
  ROSA_API_BEARER_TOKEN: `${process.env.REACT_APP_ROSA_API_BEARER_TOKEN}`,
  AMPLITUDE_API_KEY: 'ffb2784c0f24409961235025847611f4',
};

const test = {
  API_ENDPOINT_URL: `${process.env.REACT_APP_TEST_API_ENDPOINT_URL}/v1`,
  ROSA_API_ENDPOINT_URL: `${process.env.REACT_APP_ROSA_API_ENDPOINT_URL}`,
  ROSA_API_BEARER_TOKEN: `${process.env.REACT_APP_ROSA_API_BEARER_TOKEN}`,
  AMPLITUDE_API_KEY: 'ffb2784c0f24409961235025847611f4',
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'test':
      return test;
    default:
      break;
  }
};

export const env = getEnv();
