import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import history from '../history';
import { AUTH_TOKEN } from 'redux/constants/Auth';
import { notification } from 'antd';
import { store } from '../redux/store';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config
const ENTRY_ROUTE = '/auth/login';
const TOKEN_PAYLOAD_KEY = 'Authorization';
const PUBLIC_REQUEST_KEY = 'public-request';

// dispatch SIGNOUT
const myAction = () => ({ type: 'SIGNOUT' });

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: 'Error',
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: '',
      duration: 5,
    };

    // Remove token and redirect
    if (error.response.status === 400 || error.response.status === 401 || error.response.status === 403) {
      notificationParam.message = error.response.data.message;
      if (error.response.status === 400) notification.error(notificationParam);
      if (error.response.status === 401) store.dispatch(myAction());
      // notificationParam.description = error.response.description
      // localStorage.removeItem(AUTH_TOKEN)
      // history.push(ENTRY_ROUTE)
      // window.location.reload();
      // notification.error(notificationParam)
    }

    if (error.response.status === 404) {
      notificationParam.message = "Doesn't exist";
      // notification.error(notificationParam)
    }

    if (error.response.status === 500) {
      notificationParam.message = 'Internal Server Error';
      // notification.error(notificationParam)
    }

    if (error.response.status === 508) {
      notificationParam.message = 'Time Out';
      // notification.error(notificationParam)
    }

    if (error.response.status === 409) {
      notificationParam.message = 'Sorry, a similar record already exists!';
      notification.error(notificationParam);
    }

    return Promise.reject(error);
  }
);

export default service;
