import antdZhCn from 'antd/es/locale/zh_CN';
import zhMsg from '../locales/zh_CN.json';

const ZhLang = {
  antd: antdZhCn,
  locale: 'zh',
  messages: {
    ...zhMsg,
  },
};
export default ZhLang;
