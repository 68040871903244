import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { BOOKING_PREFIX_PATH } from 'configs/AppConfig';

export const BookingViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${BOOKING_PREFIX_PATH}`} component={lazy(() => import(`./book-shipment`))} />
        <Redirect from={`${BOOKING_PREFIX_PATH}`} to={`${BOOKING_PREFIX_PATH}/book_shipment`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(BookingViews);
