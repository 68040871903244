import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BookingViews from 'views/booking-flow';
import Loading from 'components/shared-components/Loading';
import { useThemeSwitcher } from 'react-css-theme-switcher';

export const BookingLayout = () => {
  const { status } = useThemeSwitcher();

  if (status === 'loading') {
    return <Loading cover="page" />;
  }

  return (
    <div className="auth-container">
      <Switch>
        <Route path="" component={BookingViews} />
      </Switch>
    </div>
  );
};

export default BookingLayout;
