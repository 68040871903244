import { v4 as uuidv4 } from 'uuid';
export function sortedVehicles(vehicles) {
  // sort vehicles
  vehicles.sort((a, b) => {
    // First, sort by model_id
    if (a.model_id < b.model_id) return -1;
    if (a.model_id > b.model_id) return 1;

    // If model_id is the same, sort by year
    if (a.year < b.year) return -1;
    if (a.year > b.year) return 1;

    // If year is also the same, sort by quantity
    if (a.quantity < b.quantity) return -1;
    if (a.quantity > b.quantity) return 1;

    // If quantity is also the same, sort by mechanicalCondition
    if (a.mechanicalCondition < b.mechanicalCondition) return -1;
    if (a.mechanicalCondition > b.mechanicalCondition) return 1;

    // If mechanicalCondition is also the same, sort by mobilityCondition
    if (a.mobilityCondition < b.mobilityCondition) return -1;
    if (a.mobilityCondition > b.mobilityCondition) return 1;

    // If all are the same, consider them equal in this sorting
    return 0;
  });
  return vehicles;
}

export function sortedCargos(cargos) {
  // sort cargos
  cargos.sort((a, b) => {
    // First, sort by item_id
    if (a.item_id < b.item_id) return -1;
    if (a.item_id > b.item_id) return 1;

    // If item_id is the same, sort by size_id
    if (a.size_id < b.size_id) return -1;
    if (a.size_id > b.size_id) return 1;

    // If size_id is also the same, sort by quantity
    if (a.quantity < b.quantity) return -1;
    if (a.quantity > b.quantity) return 1;

    // If quantity is also the same, sort by flammable
    if (a.flammable < b.flammable) return -1;
    if (a.flammable > b.flammable) return 1;

    // If flammable is also the same, sort by fragile
    if (a.fragile < b.fragile) return -1;
    if (a.fragile > b.fragile) return 1;

    // If fragile is also the same, sort by hazardous
    if (a.hazardous < b.hazardous) return -1;
    if (a.hazardous > b.hazardous) return 1;

    // If hazardous is also the same, sort by length
    if (a.length < b.length) return -1;
    if (a.length > b.length) return 1;

    // If length is also the same, sort by width
    if (a.width < b.width) return -1;
    if (a.width > b.width) return 1;

    // If width is also the same, sort by height
    if (a.height < b.height) return -1;
    if (a.height > b.height) return 1;

    // If height is also the same, sort by dimension_unit
    if (a.dimension_unit < b.dimension_unit) return -1;
    if (a.dimension_unit > b.dimension_unit) return 1;

    // If dimension_unit is also the same, sort by weight
    if (a.weight < b.weight) return -1;
    if (a.weight > b.weight) return 1;

    // If weight is also the same, sort by weight_unit
    if (a.weight_unit < b.weight_unit) return -1;
    if (a.weight_unit > b.weight_unit) return 1;

    // If weight_unit is also the same, sort by used
    if (a.used < b.used) return -1;
    if (a.used > b.used) return 1;

    // If all are the same, consider them equal in this sorting
    return 0;
  });
  return cargos;
}

export function extractCartItems(cart) {
  let vehicles = [];
  let cargos = [];

  cart.forEach((cart_item) => {
    if (cart_item.type === 'vehicle') {
      vehicles.push({
        make_and_model: cart_item.make_and_model,
        year: cart_item.year,
        title: cart_item.mechanicalCondition,
        mechanical: cart_item.mobilityCondition,
        quantity: cart_item.quantity,
        mechanicalCondition: cart_item.mechanicalCondition,
        mobilityCondition: cart_item.mobilityCondition,
        type: cart_item.type,
        id: cart_item.id,
        model_id: cart_item.model_id,
      });
    }
    if (cart_item.type === 'cargo') {
      cargos.push({
        length: cart_item.length,
        width: cart_item.width,
        height: cart_item.height,
        dimension_unit: cart_item.dimension_unit,
        weight: cart_item.weight,
        weight_unit: cart_item.weight_unit,
        fragile: cart_item.fragile,
        hazardous: cart_item.hazardous,
        flammable: cart_item.flammable,
        used: cart_item.used,
        quantity: cart_item.quantity,
        type: cart_item.type,
        cargo_type: cart_item.cargo_type,
        size: cart_item.size,
        category: cart_item.category,
        item: cart_item.item,
        item_id: cart_item.item_id,
        size_id: cart_item.size_id,
        id: cart_item.id,
        type: cart_item.type,
      });
    }
  });

  // sort vehicles
  vehicles = sortedVehicles(vehicles);

  // sort cargos
  cargos = sortedCargos(cargos);

  return { vehicles, cargos };
}

export function getVehicleSearchParams(searchParams) {
  let makes = searchParams.getAll('makes');
  let models = searchParams.getAll('models');
  let model_ids = searchParams.getAll('model_ids');
  let years = searchParams.getAll('years');
  let mechanicalCondition = searchParams.getAll('mechanicalCondition');
  let mobilityCondition = searchParams.getAll('mobilityCondition');
  let quantity = searchParams.getAll('quantity');

  // convert into array
  makes = makes.length ? makes[0].split(',') : [];
  models = models.length ? models[0].split(',') : [];
  model_ids = model_ids.length ? model_ids[0].split(',') : [];
  years = years.length ? years[0].split(',') : [];
  mechanicalCondition = mechanicalCondition.length ? mechanicalCondition[0].split(',') : [];
  mobilityCondition = mobilityCondition.length ? mobilityCondition[0].split(',') : [];
  quantity = quantity.length ? quantity[0].split(',') : [];
  return { makes, models, model_ids, years, mechanicalCondition, mobilityCondition, quantity };
}

export function getVehiclesFromSearchParams(searchParams) {
  const { makes, models, model_ids, years, mechanicalCondition, mobilityCondition, quantity } =
    getVehicleSearchParams(searchParams);

  let vehicles = [];
  for (let index = 0; index < makes.length; index++) {
    vehicles.push({
      make_and_model: [makes[index], models[index]],
      year: years[index],
      title: mechanicalCondition[index],
      mechanical: mobilityCondition[index],
      quantity: quantity[index],
      mechanicalCondition: mechanicalCondition[index],
      mobilityCondition: mobilityCondition[index],
      type: 'vehicle',
      id: uuidv4(),
      model_id: model_ids[index],
    });
  }
  vehicles = sortedVehicles(vehicles);
  return vehicles;
}

export function getCargoSearchParams(searchParams) {
  let length = searchParams.getAll('length');
  let width = searchParams.getAll('width');
  let height = searchParams.getAll('height');
  let dimension_unit = searchParams.getAll('dimension_unit');
  let weight = searchParams.getAll('weight');
  let weight_unit = searchParams.getAll('weight_unit');
  let fragile = searchParams.getAll('fragile');
  let hazardous = searchParams.getAll('hazardous');
  let flammable = searchParams.getAll('flammable');
  let used = searchParams.getAll('used');
  let cargo_quantity = searchParams.getAll('cargo_quantity');
  let type = searchParams.getAll('type');
  let cargo_type = searchParams.getAll('cargo_type');
  let size = searchParams.getAll('size');
  let category = searchParams.getAll('category');
  let item = searchParams.getAll('item');
  let item_id = searchParams.getAll('item_id');
  let size_id = searchParams.getAll('size_id');

  // convert into array
  length = length.length ? length[0].split(',') : [];
  width = width.length ? width[0].split(',') : [];
  height = height.length ? height[0].split(',') : [];
  dimension_unit = dimension_unit.length ? dimension_unit[0].split(',') : [];
  weight = weight.length ? weight[0].split(',') : [];
  weight_unit = weight_unit.length ? weight_unit[0].split(',') : [];
  fragile = fragile.length ? fragile[0].split(',') : [];
  hazardous = hazardous.length ? hazardous[0].split(',') : [];
  flammable = flammable.length ? flammable[0].split(',') : [];
  used = used.length ? used[0].split(',') : [];
  cargo_quantity = cargo_quantity.length ? cargo_quantity[0].split(',') : [];
  type = type.length ? type[0].split(',') : [];
  cargo_type = cargo_type.length ? cargo_type[0].split(',') : [];
  size = size.length ? size[0].split(',') : [];
  category = category.length ? category[0].split(',') : [];
  item = item.length ? item[0].split(',') : [];
  item_id = item_id.length ? item_id[0].split(',') : [];
  size_id = size_id.length ? size_id[0].split(',') : [];
  return {
    length,
    width,
    height,
    dimension_unit,
    weight,
    weight_unit,
    fragile,
    hazardous,
    flammable,
    used,
    cargo_quantity,
    type,
    cargo_type,
    size,
    category,
    item,
    item_id,
    size_id,
  };
}

export function getCargosFromSearchParams(searchParams) {
  const {
    length,
    width,
    height,
    dimension_unit,
    weight,
    weight_unit,
    fragile,
    hazardous,
    flammable,
    used,
    cargo_quantity,
    type,
    cargo_type,
    size,
    category,
    item,
    item_id,
    size_id,
  } = getCargoSearchParams(searchParams);

  let cargos = [];
  for (let index = 0; index < length.length; index++) {
    cargos.push({
      length: length[index],
      width: width[index],
      height: height[index],
      dimension_unit: dimension_unit[index],
      weight: weight[index],
      weight_unit: weight_unit[index],
      fragile: fragile[index],
      hazardous: hazardous[index],
      flammable: flammable[index],
      used: used[index],
      quantity: cargo_quantity[index],
      type: type[index],
      cargo_type: cargo_type[index],
      size: size[index],
      category: category[index],
      item: item[index],
      item_id: item_id[index],
      size_id: size_id[index],
      id: uuidv4(),
      type: 'cargo',
    });
  }
  cargos = sortedCargos(cargos);
  return cargos;
}

export function checkDiffInCartItems(cart, searchParams) {
  const vehicles = getVehiclesFromSearchParams(searchParams);
  const cargos = getCargosFromSearchParams(searchParams);

  let diff = false;
  let cartItems = extractCartItems(cart);
  if (JSON.stringify(cartItems.vehicles) !== JSON.stringify(vehicles)) {
    diff = true;
  }
  if (JSON.stringify(cartItems.cargos) !== JSON.stringify(cargos)) {
    diff = true;
  }
  return diff;
}

export function checkVehicleDiffInCartItems(cart, searchParams) {
  const vehicles = getVehiclesFromSearchParams(searchParams);

  let diff = false;
  let cartItems = extractCartItems(cart);
  if (JSON.stringify(cartItems.vehicles) !== JSON.stringify(vehicles)) {
    diff = true;
  }
  return diff;
}

export function checkCargoDiffInCartItems(cart, searchParams) {
  const cargos = getCargosFromSearchParams(searchParams);

  let diff = false;
  let cartItems = extractCartItems(cart);
  if (JSON.stringify(cartItems.cargos) !== JSON.stringify(cargos)) {
    diff = true;
  }
  return diff;
}
