import {
  AUTH_TOKEN,
  AUTHENTICATED,
  AUTHENTICATED_TO_SHIPMENT,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  STOP_LOADING,
  SIGNIN,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  UPDATE_USER,
  UPDATE_LOGGEDIN_AS,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS,
} from '../constants/Auth';

const getScopeDetails = (scope) => {
  if (scope?.consolidator_id) {
    return {
      name: scope.consolidators.name,
      type: 'CONSOLIDATOR',
      desc: 'Consolidator',
      consolidator_id: scope.consolidator_id,
      img: '',
      icon: 'user',
      permission_codes: scope.permission_codes,
      user: scope?.user || '',
    };
  } else if (scope?.custom_broker_id) {
    return {
      name: scope.custom_broker.name,
      custom_broker_id: scope.custom_broker_id,
      type: 'CUSTOM_BROKER',
      desc: 'Custom Broker',
      img: '',
      icon: 'user',
      permission_codes: scope.permission_codes,
      user: scope?.user || '',
    };
  } else if (scope?.deconsolidator_id) {
    return {
      name: scope.deconsolidator.name,
      deconsolidator_id: scope.deconsolidator_id,
      type: 'DECONSOLIDATOR',
      desc: 'De Consolidator',
      img: '',
      icon: 'user',
      permission_codes: scope.permission_codes,
      user: scope?.user || '',
    };
  }
};

const shipperUserScope = (user) => {
  return {
    ...user,
    name: `${user.first_name} ${user.last_name}`,
    user_id: user.id,
    type: 'SHIPPER',
    desc: 'Shipper',
    img: '',
    icon: 'user',
  };
};

const additionalUserScopes = (payload) => {
  let scopes = payload.accessScopes
    .filter(function (e) {
      return e !== null;
    })
    .map((aScope) => {
      return getScopeDetails(aScope);
    });
  if (scopes.length > 0) {
    scopes.concat(shipperUserScope(payload.user));
  }
  return scopes.filter((scope) => scope !== null && scope !== undefined);
};

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '/app/shipments',
  token: localStorage.getItem(AUTH_TOKEN),
  allowRedirect: true,
  user: null,
  loggedInAs: null,
  accessList: null,
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: '/app/shipments',
        token: action.token,
      };
    case AUTHENTICATED_TO_SHIPMENT:
      return {
        ...state,
        loading: false,
        redirect: `/app/shipments/${action.shipment_id}`,
        token: action.token,
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: '',
        showMessage: false,
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: '/',
        loading: false,
        user: null,
        loggedInAs: null,
        accessList: null,
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case STOP_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    case SIGNIN: {
      return {
        ...state,
        user: shipperUserScope(action.payload.user),
        // token: action.token,
        loggedInAs: shipperUserScope(action.payload.user),
        accessList: additionalUserScopes(action.payload),
      };
    }
    case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case UPDATE_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case UPDATE_LOGGEDIN_AS: {
      return {
        ...state,
        loggedInAs: action.payload,
      };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default auth;
