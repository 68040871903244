import {
  DashboardOutlined,
  UserOutlined,
  DropboxOutlined,
  // BookOutlined,
  // QuestionCircleOutlined,
  CodeSandboxOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { RiShipFill } from 'react-icons/ri';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const shipIcon = () => {
  return (
    <>
      <RiShipFill size={18} style={{ marginRight: '9px' }} />
    </>
  );
};

const menuNavTree = [
  {
    key: 'dashboards',
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: 'Consolidator Menu',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'freight-forwarder-dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: 'sidenav.dashboard',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: 'freight-forwarder-bookings',
      //   path: `${APP_PREFIX_PATH}/co/orders?status=pending`,
      //   title: 'Bookings',
      //   icon: DropboxOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: 'freight-forwarder-shipments',
        path: `${APP_PREFIX_PATH}/co/orders?status=accepted`,
        title: 'sidenav.shipments',
        icon: DropboxOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'freight-forwarder-ship',
        path: `${APP_PREFIX_PATH}/co/services-prices`,
        title: 'Services & Prices',
        icon: shipIcon,
        // icon: DollarOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'freight-forwarder-containers',
        path: `${APP_PREFIX_PATH}/co/containers`,
        title: 'Containers',
        icon: CodeSandboxOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'freight-forwarder-settings',
        path: `${APP_PREFIX_PATH}/settings`,
        title: 'sidenav.settings',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'freight-forwarder-reviews',
        path: `${APP_PREFIX_PATH}/co/reviews`,
        title: 'Reviews',
        icon: StarOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

// const docsNavTree = [{
//   key: 'docs',
//   path: `${APP_PREFIX_PATH}/docs`,
//   title: 'sidenav.resources',
//   icon: BookOutlined,
//   breadcrumb: false,
//   submenu: [
//     {
//       key: 'docs-documentation',
//       path: `${APP_PREFIX_PATH}/docs/documentation`,
//       title: 'sidenav.faq',
//       icon: QuestionCircleOutlined,
//       breadcrumb: false,
//       submenu: []
//     }
//   ]
// }]

const navigationConfig = [
  ...menuNavTree,
  // ...docsNavTree,
];

export default navigationConfig;
