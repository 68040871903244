import React, { useEffect, useState } from 'react';
import { Button, Typography, Cascader } from 'antd';
import { useSelector } from 'react-redux';
import { AMPLITUDE_API_KEY } from 'configs/AppConfig';
import amplitude from 'amplitude-js';
const { Title } = Typography;
import LibraryService from 'services/LibraryService';

const LangdingPageRoutePicker = () => {
  const [cascaderOptionsFromCities, setCascaderOptionsFromCities] = useState();
  const [cascaderOptionsToCities, setCascaderOptionsToCities] = useState();
  const [fromCities, setFromCities] = useState([]);
  const [toCities, setToCities] = useState([]);
  const [fromCityId, setFromCityId] = useState();
  const [toCityId, setToCityId] = useState();
  const [fromCity, setFromCity] = useState([]);
  const [toCity, setToCity] = useState([]);
  const { from_city, to_city } = useSelector((state) => state.booking);

  const onChangeFromCitySelected = (value, selectedOptions) => {
    setFromCity(value);
    setFromCityId(selectedOptions[1]?.id);
  };

  const onChangeToCitySelected = (value, selectedOptions) => {
    setToCity(value);
    setToCityId(selectedOptions[1]?.id);
  };

  const FromCityDropdown = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <span style={{ marginBottom: '5px' }}>From</span>
          <Cascader
            options={cascaderOptionsFromCities}
            onChange={onChangeFromCitySelected}
            separator=" - "
            value={fromCity}
            placeholder="Pickup City"
            style={{ minWidth: '200px' }}
          />
        </div>
      </>
    );
  };

  const ToCityDropdown = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <span style={{ marginBottom: '5px' }}>To</span>
          <Cascader
            options={cascaderOptionsToCities}
            onChange={onChangeToCitySelected}
            separator=" - "
            value={toCity}
            placeholder="Delivery City"
            style={{ minWidth: '200px' }}
          />
        </div>
      </>
    );
  };

  const clickStartShipment = () => {
    amplitude.getInstance().init(AMPLITUDE_API_KEY);
    amplitude.getInstance().logEvent('Location Selected', {
      origin_location: fromCity[1],
      destination_location: toCity[1],
    });
    window.open(`https://beta.awele.ca/booking?page=1&from_city=${fromCityId}&to_city=${toCityId}`, '_blank');
  };

  useEffect(() => {
    const cascaderOptions = fromCities.reduce((acc, item) => {
      const { country_id, city, country } = item;
      const { country: countryName } = country;

      // Find the index of the country in the accumulator
      const countryIndex = acc.findIndex((c) => c.id === country_id);

      // If country doesn't exist in accumulator, add it
      if (countryIndex === -1) {
        acc.push({
          id: country_id,
          value: countryName,
          label: countryName,
          children: [{ id: item.id, value: city, label: city }],
        });
      } else {
        // If country exists, push the city to its children
        acc[countryIndex].children.push({ id: item.id, value: city, label: city });
      }

      return acc;
    }, []);

    cascaderOptions.sort((a, b) => a.value.localeCompare(b.value));
    cascaderOptions.forEach((country) => {
      country.children.sort((a, b) => a.value.localeCompare(b.value));
    });

    setCascaderOptionsFromCities(cascaderOptions);
  }, [fromCities]);

  useEffect(() => {
    const cascaderOptions = toCities.reduce((acc, item) => {
      const { country_id, city, country } = item;
      const { country: countryName } = country;

      // Find the index of the country in the accumulator
      const countryIndex = acc.findIndex((c) => c.id === country_id);

      // If country doesn't exist in accumulator, add it
      if (countryIndex === -1) {
        acc.push({
          id: country_id,
          value: countryName,
          label: countryName,
          children: [{ id: item.id, value: city, label: city }],
        });
      } else {
        // If country exists, push the city to its children
        acc[countryIndex].children.push({ id: item.id, value: city, label: city });
      }

      return acc;
    }, []);

    cascaderOptions.sort((a, b) => a.value.localeCompare(b.value));
    cascaderOptions.forEach((country) => {
      country.children.sort((a, b) => a.value.localeCompare(b.value));
    });

    setCascaderOptionsToCities(cascaderOptions);
  }, [toCities]);

  useEffect(async () => {
    const res = await LibraryService.getCities();
    setFromCities(res.filter((city) => city.country.can_export === 'yes'));
    setToCities(res.filter((city) => city.country.can_import === 'yes'));
    if (from_city) {
      const filterFromCity = fromCities.find((city) => city.id === from_city.city_id);
      filterFromCity && setFromCity([filterFromCity.country.country, filterFromCity.city]);
    }
    if (to_city) {
      const filterToCity = toCities.find((city) => city.id === to_city.city_id);
      filterToCity && setToCity([filterToCity.country.country, filterToCity.city]);
    }
  }, []);

  return (
    <div
      style={{
        backgroundColor: 'white',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
      }}>
      <div style={{ textAlign: 'left' }}>
        <div className="d-flex justify-content-start mt-5 flex-wrap">
          <div className="mr-5 mb-3 justify-content-start flex-wrap">
            <FromCityDropdown />
          </div>
          <div className="mr-5 mb-3 justify-content-start flex-wrap">
            <ToCityDropdown />
          </div>
          <div className="mr-5 mb-3 justify-content-center flex-wrap">
            <br />
            <Button onClick={() => clickStartShipment()} className="mb-5 mt-1" type="primary">
              Get Instant Quote
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LangdingPageRoutePicker;
