import React, { useState, useEffect } from 'react';
import { Result, Input, Form, Select, Rate, message, Button, Tag, Dropdown, Space, Modal } from 'antd';
import { useSelector } from 'react-redux';
import ReviewService from 'services/ReviewService';
import jwt from 'jsonwebtoken';

const reviewCategoryList = {
  pickup: ['Driver Behavior', 'Driver Lateness', 'Shipment handling', 'Other- please specify'],
  crossborder: ['Driver Behavior', 'Driver Lateness', 'Shipment handling', 'Wrong Address', 'Other- please specify'],
  clearing: ['Staff Behavior', 'Shipment handling', 'Other- please specify'],
  delivery: ['Driver Behavior', 'Driver Lateness', 'Shipment handling', 'Other- please specify'],
};

const ReviewByShipper = () => {
  const [form] = Form.useForm();
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [consolidatorId, setConsolidatorId] = useState();
  const [consolidatorName, setConsolidatorName] = useState();
  const [reviewByShipperId, setReviewByShipperId] = useState();
  const [categoryOfService, setCategoryOfService] = useState('pickup');
  const [isOther, setIsOther] = useState(false);

  const onFinish = (values) => {
    const params = {};
    params.review_by_shipper_id = reviewByShipperId;
    params.consolidator_id = consolidatorId;
    params.service = values.service;
    params.job = values.job;
    params.rating = values.rating;
    params.content = values.content;
    params.review_category = values.review_category;
    if (values.review_category === 'Other- please specify' && values?.other) params.review_category = values.other;

    ReviewService.createReviewWithNonAuth(params)
      .then((response) => {
        message.success('Successfully created a new review');
        setSuccess(true);
      })
      .catch((error) => {
        message.error(error.message);
      });
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {};

  const handleRateChange = (value) => {
    try {
      form.setFieldsValue({
        rating: value,
      });
    } catch (error) {}
  };

  const handleCategoryOfServiceChange = (value) => {
    setCategoryOfService(value);
  };

  const handleReviewCategoryChange = (value) => {
    if (value === 'Other- please specify') setIsOther(true);
    else setIsOther(false);
  };

  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    try {
      if (token) {
        const decodedUser = jwt.decode(token);
        if (
          decodedUser &&
          decodedUser?.consolidator_id &&
          decodedUser?.consolidator_name &&
          decodedUser?.review_by_shipper_id
        ) {
          setConsolidatorId(decodedUser?.consolidator_id);
          setConsolidatorName(decodedUser?.consolidator_name);
          setReviewByShipperId(decodedUser?.review_by_shipper_id);
          setLoading(true);
        }
      }
    } catch (error) {}
  }, [token]);

  return (
    <>
      {loading && !success ? (
        <div className="mt-5 w-25 d-flex flex-row" style={{ marginLeft: '50px' }}>
          <div>
            <h1 className="mt-2">Review Page</h1>
            <Form
              layout="vertical"
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              style={{ width: '300px' }}
              className="my-form mx-3">
              <h3>{consolidatorName}</h3>
              <Form.Item
                name={'service'}
                label={'Category of service'}
                rules={[
                  {
                    required: true,
                    message: 'Please choose a category of service',
                  },
                ]}>
                <Select
                  placeholder={'Choose Category of Service'}
                  style={{ width: 200 }}
                  onChange={handleCategoryOfServiceChange}>
                  <Select.Option key={'pickup'} value={'pickup'}>
                    Pickup
                  </Select.Option>
                  <Select.Option key={'crossborder'} value={'crossborder'}>
                    Crossborder
                  </Select.Option>
                  <Select.Option key={'clearing'} value={'clearing'}>
                    Clearing
                  </Select.Option>
                  <Select.Option key={'delivery'} value={'delivery'}>
                    Delivery
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name={'review_category'}
                label={'Review Category'}
                rules={[
                  {
                    required: true,
                    message: 'Please choose a review category',
                  },
                ]}>
                <Select
                  onChange={handleReviewCategoryChange}
                  placeholder={'Choose Review Category'}
                  style={{ width: 200 }}>
                  {reviewCategoryList[categoryOfService] &&
                    reviewCategoryList[categoryOfService].map((category) => (
                      <Select.Option key={`${category}`} value={`${category}`}>
                        {category}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              {isOther && (
                <Form.Item
                  name={'other'}
                  rules={[
                    {
                      required: true,
                      message: 'Field is required!',
                    },
                  ]}>
                  <Input placeholder="Input review category" />
                </Form.Item>
              )}

              <Form.Item
                name={'job'}
                label={'Job to be done'}
                rules={[
                  {
                    required: true,
                    message: 'Please choose job',
                  },
                ]}>
                <Select placeholder={'Choose Job'} style={{ width: 200 }}>
                  <Select.Option key={'trucking'} value={'trucking'}>
                    Trucking
                  </Select.Option>
                  <Select.Option key={'storage'} value={'storage'}>
                    Storage
                  </Select.Option>
                  <Select.Option key={'loading'} value={'loading'}>
                    Loading
                  </Select.Option>
                  <Select.Option key={'shipping'} value={'shipping'}>
                    Shipping
                  </Select.Option>
                  <Select.Option key={'customs'} value={'customs'}>
                    Customs
                  </Select.Option>
                  <Select.Option key={'company'} value={'company'}>
                    Shipping company
                  </Select.Option>
                  <Select.Option key={'terminal'} value={'terminal'}>
                    Terminal
                  </Select.Option>
                  <Select.Option key={'drayage'} value={'drayage'}>
                    Drayage
                  </Select.Option>
                  <Select.Option key={'offloading'} value={'offloading'}>
                    Offloading
                  </Select.Option>
                  <Select.Option key={'delivery'} value={'delivery'}>
                    Delivery
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Overall Rating"
                name="rating"
                rules={[{ required: true, message: 'Please input your rating!' }]}>
                <Rate onChange={handleRateChange} />
              </Form.Item>

              <Form.Item
                label="Add a written review"
                name="content"
                rules={[{ required: true, message: 'Please input your label' }]}>
                <Input className="form-control" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
          {/* <div>William Smith</div> */}
        </div>
      ) : success ? (
        <div
          style={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Result
            status="success"
            title="Successfully Review!"
            // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
            extra={[]}
          />
        </div>
      ) : (
        <div
          style={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Result title="Your token is incorrect!" extra={[]} />
        </div>
      )}
    </>
  );
};

export default ReviewByShipper;
