import {
  UPDATE_FROM_CITY,
  UPDATE_TO_CITY,
  DELETE_ITEM_FROM_CART,
  ADD_ITEM_TO_CART,
  ADD_ITEMS_TO_CART,
  EMPTY_CART,
  UPDATE_SELECTED_QUOTE,
} from '../constants/Booking';

const initState = {
  from_city: null,
  to_city: null,
  cart: [],
  quote: null,
};

const booking = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_FROM_CITY:
      return {
        ...state,
        from_city: action.city,
      };
    case UPDATE_TO_CITY:
      return {
        ...state,
        to_city: action.city,
      };
    case DELETE_ITEM_FROM_CART: {
      return {
        ...state,
        cart: state.cart.filter((cart_item) => cart_item.id !== action.item_id),
      };
    }
    case ADD_ITEM_TO_CART: {
      return {
        ...state,
        cart: [...state.cart, action.cart_item],
      };
    }
    case ADD_ITEMS_TO_CART: {
      return {
        ...state,
        cart: [...state.cart, ...action.cart_items],
      };
    }
    case EMPTY_CART: {
      return {
        ...state,
        cart: [],
      };
    }
    case UPDATE_SELECTED_QUOTE: {
      return {
        ...state,
        quote: action.quote,
      };
    }
    default:
      return state;
  }
};

export default booking;
