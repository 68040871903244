import {
  DashboardOutlined,
  UserOutlined,
  DropboxOutlined,
  // BookOutlined,
  // QuestionCircleOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
// import customBrokerNavigationConfig from 'configs/CustomBrokerNavigationConfig'
// import freightForwarderNavigationConfig from 'configs/ConsolidatorNavigationConfig'
// import shipperNavigationConfig from 'configs/ShipperNavigationConfig'

const menuNavTree = [
  {
    key: 'dashboards',
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: 'Shipper Menu',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'shipper-dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: 'sidenav.dashboard',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'shipper-shipments',
        path: `${APP_PREFIX_PATH}/v2/orders`,
        title: 'sidenav.shipments',
        icon: DropboxOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'shipper-settings',
        path: `${APP_PREFIX_PATH}/settings`,
        title: 'sidenav.settings',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

// const docsNavTree = [{
//   key: 'docs',
//   path: `${APP_PREFIX_PATH}/docs`,
//   title: 'sidenav.resources',
//   icon: BookOutlined,
//   breadcrumb: false,
//   submenu: [
//     {
//       key: 'docs-documentation',
//       path: `${APP_PREFIX_PATH}/docs/documentation`,
//       title: 'sidenav.faq',
//       icon: QuestionCircleOutlined,
//       breadcrumb: false,
//       submenu: []
//     }
//   ]
// }]

const navigationConfig = [...menuNavTree];

export default navigationConfig;
