import fetch from 'auth/FetchInterceptor';
import { store } from '../redux/store';

const ReviewService = {};

ReviewService.createReview = function (data) {
  if (!data?.service) data.service = 'pickup';
  if (!data?.job) data.job = 'trucking';
  if (!data?.review_category) data.review_category = 'Driver Behavior';
  return fetch({
    url: `/review`,
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data: data,
  });
};

ReviewService.createReviewWithNonAuth = function (data) {
  if (!data?.service) data.service = 'pickup';
  if (!data?.job) data.job = 'trucking';
  if (!data?.review_category) data.review_category = 'Driver Behavior';
  return fetch({
    url: `/review/non-auth`,
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data: data,
  });
};

ReviewService.getReviews = function (params) {
  return fetch({
    url: `/review`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params: params,
  });
};

ReviewService.getReview = function (id) {
  return fetch({
    url: `/review/${id}`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
  });
};

ReviewService.getProviderReviews = function (params) {
  return fetch({
    url: `/review/provider`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params: params,
  });
};

ReviewService.getReviewBreakdowns = function () {
  const state = store.getState();
  const { user } = state.auth;
  let params = {};
  if (user && user?.id) {
    params.user_id = user.id;
  }

  return fetch({
    url: `/review/breakdowns`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
    params: params,
  });
};

ReviewService.updateReview = function (id, data) {
  return fetch({
    url: `/review/${id}`,
    method: 'patch',
    headers: {
      'public-request': 'false',
    },
    data: data,
  });
};

ReviewService.deleteReview = function (id) {
  return fetch({
    url: `/review/${id}`,
    method: 'delete',
    headers: {
      'public-request': 'false',
    },
  });
};

export default ReviewService;
