import React from 'react';
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
} from 'react-share';
import {
  FacebookOutlined,
  WhatsAppOutlined,
  MessageOutlined,
  TwitterOutlined,
  InstagramOutlined,
  MailOutlined,
  LinkOutlined,
  LinkedinOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import { message } from 'antd';

const SharePopup = (props) => {
  const { url, title, emailSubject, isPage = false } = props.data;
  const handleCopyClick = () => {
    navigator.clipboard.writeText(url).then(() => {
      message.success('Link copied to clipboard!');
    });
  };
  return (
    <div className="share-popup">
      <div
        style={{ fontSize: '120%', fontWeight: 'bold' }}
        className="text-center mt-3 mb-4 p-2 border-3 border-bottom">
        Share {isPage === true ? `This Page` : `Quote`}
      </div>
      <div className="share-buttons">
        <FacebookShareButton url={url} quote={title}>
          <FacebookOutlined style={{ fontSize: '32px' }} />
        </FacebookShareButton>
        <TwitterShareButton url={url} title={emailSubject}>
          <TwitterOutlined style={{ fontSize: '32px' }} />
        </TwitterShareButton>
        <InstapaperShareButton url={url} title={title}>
          <InstagramOutlined style={{ fontSize: '32px' }} />
        </InstapaperShareButton>
        <LinkedinShareButton url={url} title={title}>
          <LinkedinOutlined style={{ fontSize: '32px' }} />
        </LinkedinShareButton>
        <WhatsappShareButton url={url} title={title}>
          <WhatsAppOutlined style={{ fontSize: '32px' }} />
        </WhatsappShareButton>
        <a href={`sms:?body=${title} ${url}`}>
          <MessageOutlined style={{ fontSize: '32px' }} />
        </a>
        <EmailShareButton url={url} subject={emailSubject} body={title}>
          <MailOutlined style={{ fontSize: '32px' }} />
        </EmailShareButton>
      </div>
      <hr className="separator-line" />
      <div className="url-display">
        <LinkOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
        <input className="url-input" type="text" value={url} readOnly />
        <button className="antd-btn copy-button" onClick={handleCopyClick} type="button">
          <CopyOutlined />
        </button>
      </div>
    </div>
  );
};

export default SharePopup;
