import {
  DashboardOutlined,
  UserOutlined,
  // DropboxOutlined,
  // BookOutlined,
  // QuestionCircleOutlined,
  CodeSandboxOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { RiShipFill } from 'react-icons/ri';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const shipIcon = () => {
  return (
    <>
      <RiShipFill size={18} style={{ marginRight: '9px' }} />
    </>
  );
};

const menuNavTree = [
  {
    key: 'custom-broker-dashboards',
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: 'Custom Broker Menu',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'custom-broker-dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: 'sidenav.dashboard',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'custom-broker-ship',
        path: `${APP_PREFIX_PATH}/co/services-prices`,
        title: 'Services & Prices',
        icon: shipIcon,
        // icon: DollarOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'custom-broker-containers',
        path: `${APP_PREFIX_PATH}/cb/containers`,
        title: 'Containers',
        icon: CodeSandboxOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'custom-broker-settings',
        path: `${APP_PREFIX_PATH}/settings`,
        title: 'sidenav.settings',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'custom-broker-reviews',
        path: `${APP_PREFIX_PATH}/cb/reviews`,
        title: 'Reviews',
        icon: StarOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

// const docsNavTree = [
//   {
//     key: "docs",
//     path: `${APP_PREFIX_PATH}/docs`,
//     title: "sidenav.resources",
//     icon: BookOutlined,
//     breadcrumb: false,
//     submenu: [
//       {
//         key: "docs-documentation",
//         path: `${APP_PREFIX_PATH}/docs/documentation`,
//         title: "sidenav.faq",
//         icon: QuestionCircleOutlined,
//         breadcrumb: false,
//         submenu: [],
//       },
//     ],
//   },
// ];

const navigationConfig = [
  ...menuNavTree,
  // ...docsNavTree,
];

export default navigationConfig;
