import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/v2`} component={lazy(() => import(`./V2`))} />
        <Route path={`${APP_PREFIX_PATH}/co`} component={lazy(() => import(`./consolidator`))} />
        <Route path={`${APP_PREFIX_PATH}/cb`} component={lazy(() => import(`./custom-broker`))} />
        <Route
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./consolidator/dashboard/default`))}
        />
        <Route path={`${APP_PREFIX_PATH}`} component={lazy(() => import(`./shipper`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/shipper`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
