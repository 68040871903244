// import {
//   DashboardOutlined,
//   UserOutlined,
//   DropboxOutlined,
//   BookOutlined,
//   QuestionCircleOutlined
// } from '@ant-design/icons';
// import { APP_PREFIX_PATH } from "configs/AppConfig";
import customBrokerNavigationConfig from 'configs/CustomBrokerNavigationConfig';
import freightForwarderNavigationConfig from 'configs/ConsolidatorNavigationConfig';
import shipperNavigationConfig from 'configs/ShipperNavigationConfig';

const navigationConfig = [
  ...shipperNavigationConfig,
  ...freightForwarderNavigationConfig,
  ...customBrokerNavigationConfig,
];

export default navigationConfig;
