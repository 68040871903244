import {
  UPDATE_FROM_CITY,
  UPDATE_TO_CITY,
  DELETE_ITEM_FROM_CART,
  ADD_ITEM_TO_CART,
  ADD_ITEMS_TO_CART,
  EMPTY_CART,
  UPDATE_SELECTED_QUOTE,
} from '../constants/Booking';

export const updateFromCity = (city) => {
  return {
    type: UPDATE_FROM_CITY,
    city: city,
  };
};

export const updateToCity = (city) => {
  return {
    type: UPDATE_TO_CITY,
    city: city,
  };
};

export const deleteItemFromCart = (item_id) => {
  return {
    type: DELETE_ITEM_FROM_CART,
    item_id: item_id,
  };
};

export const addItemToCart = (cart_item) => {
  return {
    type: ADD_ITEM_TO_CART,
    cart_item: cart_item,
  };
};

export const addItemsToCart = (cart_items) => {
  return {
    type: ADD_ITEMS_TO_CART,
    cart_items: cart_items,
  };
};

export const emptyCart = () => {
  return {
    type: EMPTY_CART,
  };
};

export const updateSelectedQuote = (quote) => {
  return {
    type: UPDATE_SELECTED_QUOTE,
    quote: quote,
  };
};
